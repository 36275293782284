import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
  fetchAddresses,
  fetchAddress,
  createAddress,
  updateAddress,
  searchAddress,
  deleteAddress,
} from "@/api/address";

export type Address = {
  id: number;
  name: string;
  country: string;
  province: string;
  city: string;
  suburb: string;
  complexName: string;
  unitNumber: string;
  streetName: string;
  streetNumber: string;
  postalCode: string;
  note: string;
  latitude: number;
  longitude: number;
  isDisabled: boolean;
  isPrimary: boolean;
  isFavourite: boolean;
  isBilling: boolean;
  isCollection: boolean;
  isDelivery: boolean;
  userId: string;
  businessId: number;
};

export interface AddressesState {
  addresses: Address[];
  selectedAddress?: Address;
  geoSearchAddresses: any;
  loading: boolean;
}

export const state: AddressesState = {
  addresses: [],
  selectedAddress: undefined,
  geoSearchAddresses: [],
  loading: false,
};

const namespaced: boolean = true;

export const getters: GetterTree<AddressesState, RootState> = {};

export const actions: ActionTree<AddressesState, RootState> = {
  async fetchAddresses({ commit, dispatch, rootState }) {
    try {
      commit("loading");
      const response = await fetchAddresses(rootState.shops.activeShop.id);
      commit("setAddresses", response.data.results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchAddress({ commit, dispatch, rootState }, addressId: number) {
    try {
      commit("loading");
      const response = await fetchAddress(addressId);
      commit("setSelectedAddress", response.data);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async createAddress({ commit, dispatch, rootState }, address: Address) {
    try {
      commit("loading");
      await createAddress(rootState.shops.activeShop.id, address);
      commit("notifications/success", "New Address Create", { root: true });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async deleteAddress({ commit, dispatch }, addressId) {
    try {
      commit("loading");
      await deleteAddress(addressId);
      commit("notifications/success", "Address Deleted", { root: true });
      dispatch("fetchAddresses");
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async updateAddress({ commit, dispatch }, address: Address) {
    try {
      commit("loading");
      const addressData = JSON.parse(JSON.stringify(address));
      const addressId = addressData.id;
      delete addressData.id;
      delete addressData.businessId;
      await updateAddress(addressId, addressData);
      commit("notifications/success", "Address Updated", { root: true });
      dispatch("fetchAddresses");
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      dispatch("fetchAddresses");
      commit("loading", false);
    }
  },

  async setSelectedAddress({ commit }, address: Address) {
    commit("setSelectedAddress", address);
  },

  async searchAddress({ commit, dispatch }, searchText: string) {
    try {
      commit("loading");
      const response = await searchAddress(searchText);
      const responseView = response.data.Response.View;
      const result: any = [];
      for (let view of responseView)
        if (view.ViewId === 0) result.push(...view.Result);

      commit("setSearchedAddresses", result);
      return true;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },
};

export const mutations: MutationTree<AddressesState> = {
  setAddresses(state, payload) {
    state.addresses = payload;
    state.loading = false;
  },
  setSelectedAddress(state, payload) {
    state.selectedAddress = payload;
    state.loading = false;
  },
  setSearchedAddresses(state, payload) {
    state.geoSearchAddresses = payload;
    state.loading = false;
  },

  loading(state, payload = true) {
    state.loading = payload;
  },
};

export const addresses: Module<AddressesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default addresses;
